<template>
  <section>
    <fw-panel
      :title="$t('comments')"
      featured
      sticky
      :loading="savingComment"
      after-loading-checked
      :loading-error="savingCommentError"
      class="mb-5"
    >
    </fw-panel>
    <PanelMessages
      :messages="comments"
      :users="users"
      :loading="loadingComments"
      :force-hide-private-messages="!isManagerOrSubmanager"
      :show-public-private-toggle="isManagerOrSubmanager"
      :can-add-message="canComment"
      :with-message-leading="true"
      @add-message="addComment"
      @delete-message="deleteComment"
    >
    </PanelMessages>
  </section>
</template>

<script>
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'

export default {
  components: {
    PanelMessages
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    canComment: {
      type: Boolean,
      default: true
    },
    justification: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      comments: [],
      users: {},
      loadingComments: false,
      savingComment: false,
      savingCommentError: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    isManagerOrSubmanager() {
      return this.justification.manager == this.user.key || this.justification.sub_managers.includes(this.user.key)
    },

    permissions() {
      return this.$store.getters.userPermissions
    }
  },

  mounted() {
    this.getJustificationComments()
  },

  methods: {
    async getJustificationComments() {
      this.loadingComments = true

      try {
        let response
        if (this.isManagerOrSubmanager || this.permissions.isManager) {
          response = await this.api.getJustificationComments(this.justification.key, {
            with_users: true
          })
        } else {
          response = await this.api.getUserJustificationComments(this.justification.key, {
            with_users: true
          })
        }

        console.log('getJustificationComments :>> ', response)
        this.users = response.users
        this.comments = response.comments
      } catch (e) {
        console.log('Error getJustificationComments :>> ', e)
      }

      this.loadingComments = false
    },

    deleteComment(commentKey) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Tem a certeza que deseja eliminar o comentário?',
        onConfirm: async () => {
          console.log('delete commentKey :>> ', commentKey)
        },
        confirmText: 'Eliminar',
        cancelText: 'Cancelar'
      })
    },

    async addComment(newComment) {
      this.savingComment = true
      this.savingCommentError = false
      console.log('add commentKey :>> ', newComment)
      const body = {
        comment: newComment.description,
        with_users: true,
        file_keys: newComment.files.map(el => el.key),
        is_private: newComment.is_private
      }
      try {
        let response
        if (this.isManagerOrSubmanager || this.permissions.isManager) {
          response = await this.api.addJustificationComment(this.justification.key, body)
        } else {
          response = await this.api.addUserJustificationComment(this.justification.key, body)
        }

        console.log('response :>> ', response)
        this.comments = response.comments
      } catch (error) {
        console.log('error :>> ', error)
        this.savingCommentError = true
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar guardar o comentário',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false
        })
      }
      this.savingComment = false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "comments": "Comentários",
    "notDefined": "Não definido",
    "people": "Pessoas",
    "manager": "Manager",
    "subManagers": "Autorizadores",
    "close": "Fechar",
    "cancel": "Cancelar",
    "send": "Enviar",
    "newComment": "Novo comentário",
    "publicComments": "Comentário público",
    "selectThisOption": "Selecione esta opção para os comentários serem apenas vísiveis por responsáveis associados a justificação.",
    "noComments": "Sem comentários",
    "errors": {
      "requiredComment": "Insira um comentário",
      "invalidComment": "Comentário inválido"
    }
  },
  "en": {
    "comments": "Comments",
    "notDefined": "Not defined",
    "people": "People",
    "manager": "Manager",
    "subManagers": "Sub managers",
    "close": "Close",
    "cancel": "Cancel",
    "send": "Send",
    "newComment": "New comment",
    "publicComments": "Public comment",
    "selectThisOption": "Select this option for comments to be visible only by officers associated with justification.",
    "noComments": "No comments",
    "errors": {
      "requiredComment": "Enter a comment",
      "invalidComment": "Invalid comment"
    }
  }
}
</i18n>
