<template>
  <PanelNotifications
    :active-filters="activeFilters"
    :details-payload="{ item_type: 'justification', item_key: justificationKey }"
  />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  components: {
    PanelNotifications
  },

  props: {
    justificationKey: {
      type: String
    }
  },

  data() {
    return {
      activeFilters: {
        justification: this.justificationKey
      }
    }
  }
}
</script>
