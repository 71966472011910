<template>
  <ExpandList
    button-custom-class="px-0"
    :add-title="'Adicionar intervalo de datas'"
    :empty="!justification.dates || justification.dates.length < 1"
    :show-add-items="canEdit && editMode && justification.motive && Boolean(selectedJustificationType.multipleDates)"
    class="flex flex-col gap-2"
    @add-element="$emit('add-date-interval')"
  >
    <template #list>
      <div
        v-for="(v, index) in vuelidateJustification.dates.$each.$iter"
        :key="index"
        class="flex justify-between items-center"
      >
        <div class="flex gap-8 items-center">
          <div>
            <fw-label>{{ $t('startDate') }}</fw-label>
            <b-datepicker
              v-if="editMode && canEdit"
              v-model="v.start_at.$model"
              :disabled="!canEdit"
              :max-date="maxDate"
              locale="pt-PT"
              icon="calendar-alt"
              trap-focus
              :placeholder="$t('startDate')"
            >
            </b-datepicker>
            <div v-else>
              <div v-if="justification.dates[index].start_at">
                {{ justification.dates[index].start_at | formatDate }}
              </div>
              <fw-panel-info v-else clean>{{ justification.dates[index].start_at || $t('notDefined') }}</fw-panel-info>
            </div>
            <fw-tip v-if="v.start_at.$error" error>
              {{ $t('errors.invalid.date') }}
            </fw-tip>
          </div>

          <div>
            <fw-label>{{ $t('endDate') }}</fw-label>
            <b-datepicker
              v-if="editMode && canEdit"
              v-model="v.end_at.$model"
              :max-date="maxDate"
              :disabled="!canEdit"
              locale="pt-PT"
              icon="calendar-alt"
              trap-focus
              :placeholder="$t('endDate')"
            >
            </b-datepicker>
            <div v-else>
              <div v-if="justification.dates[index].end_at">
                {{ justification.dates[index].end_at | formatDate }}
              </div>
              <fw-panel-info v-else clean>{{ justification.dates[index].end_at || $t('notDefined') }}</fw-panel-info>
            </div>

            <fw-tip v-if="v.end_at.$error" error>
              {{ $t('errors.invalid.date') }}
            </fw-tip>
          </div>
        </div>
        <fw-button
          v-if="
            canEdit &&
              editMode &&
              justification.motive &&
              Boolean(selectedJustificationType.multipleDates) &&
              justification.dates.length > 1
          "
          type="basic-action"
          size="sm"
          label="Eliminar"
          class="self-end"
          @click.native="$emit('delete-date-interval', index)"
        >
          <fw-icon-trash class="w-5 h-5" /> {{ $t('delete') }}
        </fw-button>
      </div>
    </template>
  </ExpandList>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'

export default {
  components: {
    ExpandList
  },

  props: {
    canEdit: {
      type: Boolean,
      default: true
    },
    editMode: {
      type: Boolean,
      default: true
    },
    vuelidateJustification: {
      type: Object,
      default: () => {}
    },
    justification: {
      type: Object,
      default: () => {}
    },
    justificationTypes: {
      type: Object,
      default: () => {}
    },
    motive: {
      type: String,
      default: null
    },
    selectedJustificationType: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      maxDate: new Date()
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },

  methods: {}
}
</script>

<i18n>
{
  "pt": {
    "notDefined": "Não definido",
    "startDate": "Data de início",
    "endDate": "Data de fim",
    "startTime": "Hora de início",
    "endTime": "Hora de fim",
    "delete": "Eliminar",
    "errors": {
      "invalid": {
        "date": "Data inválida",
        "time": "Hora inválida"
      }
    }
  },
  "en": {
    "notDefined": "Not defined",
    "startDate": "Start date",
    "endDate": "End date",
    "startTime": "Start time",
    "endTime": "End time",
    "delete": "Delete",
    "errors": {
      "invalid": {
        "date": "Invalid date",
        "time": "Invalid time"
      }
    }
  }
}
</i18n>
