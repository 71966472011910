<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" :label="$t('actions')">
      <fw-icon-actions class="w-5 h-5" :class="{ 'text-primary animate-pulse': activeActionsCounter }" />
      <span :class="{ 'text-primary': activeActionsCounter }">{{ $t('actions') }}</span>
      <fw-badge v-if="activeActionsCounter">{{ activeActionsCounter }}</fw-badge>
    </fw-button-dropdown>
    <b-dropdown-item
      v-if="!activeActionsCounter && !activeActionsHiddenFromCounter"
      custom
      class="paddingless w-52 text-gray-500"
    >
      {{ $t('noActions') }}
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionDelete" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button type="basic-action" expanded custom-class="text-red-400" @click.native="$emit('delete')">{{
        $t('delete')
      }}</fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionSubmit" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="submitButton"
        :type="'basic-action'"
        :disabled="!validations.canSubmit"
        class="w-full my-0.5 text-left"
        @click.native="$emit('submit')"
      >
        {{ $t('submit') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionManagerReview" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="showManagerReviewAction"
        :type="'basic-action'"
        :disabled="!validations.canManagerReview"
        class="w-full my-0.5 text-left"
        @click.native="$emit('review')"
      >
        {{ $t('managerReview') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionReview" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="shoReviewAction"
        :type="'basic-action'"
        :disabled="!validations.canReview"
        class="w-full my-0.5 text-left"
        @click.native="$emit('review')"
      >
        {{ $t('review') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionOpen" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="shoOpenAction"
        :type="'basic-action'"
        :disabled="!validations.canOpen"
        class="w-full my-0.5 text-left"
        @click.native="$emit('draft')"
      >
        Devolver ao trabalhador
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionRevertToManager" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="showActionRevertToManager"
        :type="'basic-action'"
        :disabled="!validations.canOpen"
        class="w-full my-0.5 text-left"
        @click.native="$emit('manager-review')"
      >
        Devolver ao responsável
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionClose" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="closeAction"
        :type="'basic-action'"
        :disabled="!validations.canClose"
        class="w-full my-0.5 text-left"
        @click.native="$emit('close')"
      >
        {{ $t('close') }}
      </fw-button>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    validations: {
      type: Object,
      default: () => {
        return {}
      }
    },
    justification: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      availableActions: [
        'showActionSubmit',
        'showActionReview',
        'showActionManagerReview',
        'showActionClose',
        'showActionOpen',
        'showActionRevertToManager'
      ], // Confirm every computed value to update counter
      availableActionsSkipCounter: ['showActionDelete']
    }
  },

  computed: {
    showActionDelete() {
      return this.validations.canDelete
    },
    showActionSubmit() {
      return this.validations.canSubmit
    },
    showActionClose() {
      return this.validations.canClose
    },
    showActionReview() {
      return this.validations.canReview
    },
    showActionManagerReview() {
      return this.validations.canManagerReview
    },
    showActionOpen() {
      return this.validations.canOpen
    },
    showActionRevertToManager() {
      return this.validations.canOpen
    },
    activeActionsCounter() {
      var counter = 0
      var self = this
      for (const action of self.availableActions) {
        if (self[action]) counter += 1
      }
      return counter
    },
    activeActionsHiddenFromCounter() {
      var counter = 0
      var self = this
      for (const action of self.availableActionsSkipCounter) {
        if (self[action]) counter += 1
      }
      return counter
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "actions": "Ações",
    "noActions": "Não existem ações disponíveis neste momento.",
    "delete": "Eliminar",
    "submit": "Submeter",
    "close": "Fechar"
  },
  "en": {
    "actions": "Actions",
    "noActions": "No actions available at this time.",
    "delete": "Delete",
    "submit": "Submit",
    "close": "Close"
  }
}
</i18n>
