<template>
  <PanelActivity :active-filters="activeFilters" />
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'

export default {
  components: {
    PanelActivity
  },

  props: {
    justificationKey: {
      type: String
    }
  },

  data() {
    return {
      activeFilters: {
        justification: this.justificationKey
      }
    }
  }
}
</script>
