<template>
  <section>
    <PanelJustificationDashboard
      v-if="section == 'dashboard'"
      :justification="justification"
      :absence="absence"
      :users="users"
      :justification-status="justificationStatus"
      :loading="loading"
      :justification-types="justificationTypes"
    />
    <PanelJustificationComments
      v-else-if="section == 'comments'"
      :justification="justification"
      :loading="loading"
      :can-comment="validations.canComment"
    />
    <PanelJustificationActivity
      v-else-if="section == 'activity'"
      :justification-key="justification.key"
      :loading="loading"
    />
    <PanelJustificationNotifications
      v-else-if="section == 'notifications'"
      :justification-key="justification.key"
      :loading="loading"
    />
    <PanelJustificationData
      v-else
      :justification.sync="justification"
      :absence.sync="absence"
      :relationship-types="relationshipTypes"
      :non-attributable-facts-types="nonAttributableFactsTypes"
      :patient-types="patientTypes"
      :justification-types="justificationTypes"
      :loading="loading"
      :can-edit="canEdit"
      :saving-data="savingData"
      :saving-data-error="savingDataError"
      :user="users[justification.owner]"
      @save="$emit('save', $event)"
    />
  </section>
</template>

<script>
import PanelJustificationData from '@/components/panels/PanelJustificationData'
import PanelJustificationComments from '@/components/panels/PanelJustificationComments'
import PanelJustificationDashboard from '@/components/panels/PanelJustificationDashboard'
import PanelJustificationActivity from '@/components/panels/PanelJustificationActivity'
import PanelJustificationNotifications from '@/components/panels/PanelJustificationNotifications'

export default {
  components: {
    PanelJustificationData,
    PanelJustificationComments,
    PanelJustificationDashboard,
    PanelJustificationActivity,
    PanelJustificationNotifications
  },

  props: {
    savingData: {
      type: Boolean,
      default: false
    },
    savingDataError: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: () => {}
    },
    absence: {
      type: Object,
      default: () => {}
    },
    justification: {
      type: Object,
      required: true,
      default: () => {}
    },
    comments: {
      type: Array,
      default: () => []
    },
    users: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      relationshipTypes: [
        {
          value: 'spouseOrAlike',
          text: {
            pt: 'Cônjuge ou equiparado, parente/afim ascendente no 1.º grau da linha reta', // até 5 dias
            en: 'Spouse or alike, relative/kin in 1st degree ascending line' // até 5 dias
          }
        },
        {
          value: '1stDegreeRelative',
          text: {
            pt: 'Descendente/afim no 1.º grau da linha reta', // até 20 dias
            en: 'Descendant/Affiliate in the 1st degree in a straight line' // até 20 dias
          }
        },
        {
          value: '2ndDegreeRelative',
          text: {
            pt: 'Outro parente/afim no 2.º grau da linha reta ou no 2º grau da linha colateral', // até 2 dias
            en: 'Other relative/kin in the 2nd degree in the direct line or in the 2nd degree in the collateral line' // até 2 dias
          }
        }
      ],
      nonAttributableFactsTypes: [
        {
          value: 'legalObligations',
          text: {
            pt: 'Cumprimento obrigações legais',
            en: 'Compliance with legal obligations'
          }
        },
        {
          value: 'others',
          text: {
            pt: 'Outros',
            en: 'Other'
          }
        }
      ],

      patientTypes: [
        {
          value: 'itself',
          text: {
            pt: 'Do/a próprio/a',
            en: 'From yourself'
          }
        },
        {
          value: 'family',
          text: {
            pt: 'Do/a cônjuge ou equiparado, ascendentes, descendentes, adotandos/as, adotados/as e enteados/as',
            en: 'Of the spouse or alike, ascendants, descendants, adoptees, foster parents and stepchildren'
          }
        },
        {
          value: 'minorsOrDisabled',
          text: {
            pt: 'Menores ou deficientes',
            en: 'Minors or disabled'
          }
        }
      ],
      justificationTypes: {
        wedding: {
          label: this.$t('motives.wedding'),
          justificationFile: true,
          withTime: false,
          helpText: 'Al. a) do n.º 2, do artigo 134.º da Lei Geral de Trabalho em Funções Públicas (LTFP)'
        },
        deathOfRelativeOrKin: {
          label: this.$t('motives.deathOfRelativeOrKin'),
          justificationFile: true,
          withTime: false,
          helpText:
            'As ausências ao serviço são contadas de forma consecutiva, apenas em dias em que o/a trabalhador/a se encontra obrigado/a ao cumprimento do seu período normal de trabalho diário <br> al. b) do n.º 2 do artigo 134.º da LTFP'
        },
        nonAttributableFacts: {
          label: this.$t('motives.nonAttributableFacts'),
          justificationFile: true,
          withTime: true,
          helpText: 'Al. d) do n.º 2, do artigo 134.º da LTFP'
        },
        medical: {
          label: this.$t('motives.medical'),
          justificationFile: true,
          withTime: true,
          helpText: 'Al. i) do n.º 2 e n.º 3, do artigo 134.º da LTFP'
        },
        educationalSituationOfMinor: {
          label: this.$t('motives.educationalSituationOfMinor'),
          justificationFile: true,
          withTime: true,
          helpText:
            'Não superior a quatro horas e só pelo tempo estritamente necessário, por trimestre e por cada menor<br>Al. f) do n.º 2, do artigo 134.º da LTFP'
        },

        firstDayOfSchool: {
          label: this.$t('motives.firstDayOfSchool'),
          justificationFile: true,
          withTime: true,
          helpText: 'Até 3 horas por cada menor<br>Artigo 3.º do Decreto-Lei n.º 85/2019, de 1 de julho'
        },
        bloodDonationFirstAid: {
          label: this.$t('motives.bloodDonationFirstAid'),
          justificationFile: true,
          withTime: true,
          helpText: 'Al. k) do n.º 2, do artigo 134.º da LTFP'
        },
        procedure: {
          label: this.$t('motives.procedure'),
          justificationFile: true,
          withTime: true,
          helpText: 'Al. l) do n.º 2, do artigo 134.º da LTFP'
        },
        electedWorkers: {
          label: this.$t('motives.electedWorkers'),
          justificationFile: true,
          withTime: true,
          helpText: 'Al. g) do n.º 2, do artigo 134.º e artigos 316.º e 323.º da LTFP'
        },
        publicOfficeCandidate: {
          label: this.$t('motives.publicOfficeCandidate'),
          justificationFile: true,
          withTime: false,
          multipleDates: true,
          helpText:
            'O/A trabalhador/a pode faltar meios dias ou dias completos, durante o respetivo período legal, com aviso prévio de 48 horas - al. h) do n.º 2, do artigo 134.º da LTFP e n.º 3 do artigo 253.º do Código de Trabalho'
        },
        prenatalConsultations: {
          label: this.$t('motives.prenatalConsultations'),
          justificationFile: true,
          withTime: false,
          helpText:
            'A trabalhadora grávida tem direito a dispensa de trabalho pelo tempo e número de vezes necessários. O pai tem direito a três dispensas para acompanhar a trabalhadora - artigo 46.º do Código Trabalho'
        },
        timeCreditUse: {
          label: this.$t('motives.timeCreditUse'),
          justificationFile: false,
          withTime: false,
          multipleDates: true,
          helpText:
            'Em todas as modalidades de horário pode ser solicitada autorização para o gozo de crédito de horas, de acordo com as regras estabelecidas nos artigos seguintes: n.º 8 do artigo 24.º (horário flexível), n.º 10 do artigo 25.º (horário rígido), n.º 2 do artigo 26.º (horário desfasado), n.º 6 do artigo 27.º (jornada contínua), n.º 8 do artigo 28.º (meia jornada) e n.º 8 do artigo 29.º (trabalho por turnos), todos do Regulamento n.º 56/2020, publicado em Diário da República n.º 15/2020, 2.ª série, de 2020-01-22'
        },
        externalService: {
          label: this.$t('motives.externalService'),
          justificationFile: false,
          withTime: false,
          multipleDates: true,
          helpText:
            'N.º 2 do artigo 16.º do Regulamento n.º 56/2020, publicado em Diário da República n.º 15/2020, 2.ª série, de 2020-01-22'
        },
        occasionalInterruptions: {
          label: this.$t('motives.occasionalInterruptions'),
          justificationFile: false,
          withTime: true,
          helpText:
            'Em situações excecionais e devidamente fundamentadas, podem ser autorizadas até 4 horas mensais de interrupção de trabalho, seguidas ou interpoladas, durante períodos de presença obrigatória - artigo 8.º do Regulamento n.º 56/2020, publicado em Diário da República n.º 15/2020, 2.ª série, de 2020-01-22'
        },
        annualCredit: {
          label: this.$t('motives.annualCredit'),
          justificationFile: false,
          withTime: false,
          multipleDates: true,
          helpText:
            'O gozo do crédito anual pode ocorrer em períodos de meios dias ou por dias completos, carecendo sempre de autorização do Superior Hierárquico, a solicitar com uma antecedência mínima de 5 dias úteis - artigo 30.º do Regulamento n.º 56/2020, publicado em Diário da República n.º 15/2020, 2.ª série, de 2020-01-22'
        },
        other: {
          label: this.$t('motives.other'),
          justificationFile: true,
          multipleDates: true,
          withTime: true,
          helpText: 'Al. n) do n.º 2, do artigo 134.º da LTFP'
        }
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    justificationKey() {
      return this.$route.params.key
    },

    section() {
      return this.$route.params.section
    },

    canEdit() {
      return Boolean(this.validations.canEdit)
    },

    justificationStatus() {
      return {
        draft: {
          label: this.$t('status.draft'),
          canChange: Boolean(this.validations.canSubmit),
          isActive: this.justification.status == 'draft',
          isDone: this.justification.status != 'draft'
        },
        submitted: {
          label: this.$t('status.submitted'),
          canChange: Boolean(this.validations.canReview),
          isActive: this.justification.status == 'submitted',
          isDone: this.justification.status != 'draft' && this.justification.status != 'submitted'
        },
        reviewed: {
          label: this.$t('status.reviewed'),
          canChange: Boolean(this.validations.canManagerReview),
          isActive: this.justification.status == 'reviewed',
          isDone: this.justification.status == 'manager_reviewed' || this.justification.status == 'closed'
        },
        manager_reviewed: {
          label: this.$t('status.manager_reviewed'),
          canChange: Boolean(this.validations.canClose),
          isActive: this.justification.status == 'manager_reviewed',
          isDone: this.justification.status == 'closed'
        },
        closed: {
          label: this.$t('status.closed'),
          canChange: false,
          isActive: this.justification.status == 'closed',
          isDone: this.justification.status == 'closed'
        }
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "personalDetails": "Dados pessoais",
    "absenceJustification": "Justificação de ausência",
    "startDate": "Data de início",
    "endDate": "Data de fim",
    "startTime": "Hora de início",
    "endTime": "Hora de fim",
    "absenceMotive": "Motivo da falta",
    "uploadFiles": "Carregar ficheiros",
    "hasErrors": "Existem erros no formulário",
    "cancel": "Cancelar",
    "reopen": "Reabrir",
    "submit": "Submeter",
    "save": "Guardar",
    "motives": {
      "wedding": "Casamento",
      "deathOfRelativeOrKin": "Falecimento de familiar ou afins",
      "nonAttributableFacts": "Factos não imputáveis ao/à trabalhador/a",
      "medical": "Pela necessidade de tratamento ambulatório, realização de consultas médicas ou exames complementares de diagnóstico",
      "educationalSituationOfMinor": "Deslocação à escola para se inteirar da situação educativa de filho/a menor",
      "firstDayOfSchool": "Acompanhamento de filho/a menor de 12 anos no primeiro dia de escola",
      "bloodDonationFirstAid": "Doação de sangue e socorrismo",
      "procedure": "Necessidade de submissão a métodos de seleção em procedimento concursal",
      "electedWorkers": "Trabalhadores/as eleitos/as para estruturas de representação coletiva",
      "publicOfficeCandidate": "Candidatos/as a eleições para cargos públicos durante o período legal da campanha eleitoral",
      "prenatalConsultations": "Dispensa para consultas pré-natais",
      "timeCreditUse": "Gozo de crédito de horas do mês anterior",
      "externalService": "Serviço externo no(s) dia(s)",
      "occasionalInterruptions": "Interrupções ocasionais",
      "annualCredit": "Crédito Anual",
      "other": "Outras faltas previstas na Lei"
    },
    "status": {
      "label": "Estado",
      "draft": "Rascunho",
      "submitted": "Submetida",
      "approved": "Aprovada",
      "evaluated": "Avaliada",
      "denied": "Rejeitada",
      "reviewed": "Revisto",
      "manager_reviewed": "Revisto pelo responsável",
      "closed": "Fechada"
    }
  },
  "en": {
    "personalDetails": "Personal details",
    "absenceJustification": "Absence justification",
    "startDate": "Start date",
    "endDate": "End date",
    "startTime": "Start time",
    "endTime": "End time",
    "absenceMotive": "Reason for absence",
    "uploadFiles": "Upload files",
    "hasErrors": "There are errors in the form",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "submit": "Submit",
    "save": "Save",
    "motives": {
      "wedding": "Wedding",
      "deathOfRelativeOrKin": "Death of relative or kin",
      "nonAttributableFacts": "Factors not attributable to the employee",
      "medical": "For the need for outpatient treatment, medical appointments, or complementary diagnostic tests",
      "educationalSituationOfMinor": "Trip to the school to find out about the educational situation of a minor child",
      "firstDayOfSchool": "Accompaniment of a child under 12 on the first day of school",
      "bloodDonationFirstAid": "Blood donation and first aid",
      "procedure": "Need to submit to selection methods in a competitive bidding procedure",
      "electedWorkers": "Workers elected to collective representation structures",
      "publicOfficeCandidate": "Candidates for public office during the legal period of the election campaign",
      "prenatalConsultations": "Exemption for prenatal consultations",
      "timeCreditUse": "Use of time credit from the previous month",
      "externalService": "External service on the day(s)",
      "occasionalInterruptions": "Occasional interruptions",
      "annualCredit": "Annual Credit",
      "other": "Other absences foreseen in the law"
    },
    "status": {
      "label": "Status",
      "draft": "Draft",
      "submitted": "Submitted",
      "approved": "Approved",
      "evaluated": "Evaluated",
      "denied": "Rejected",
      "reviewed": "Reviewed",
      "manager_reviewed": "Reviewed by manager",
      "closed": "Closed"
    }
  }
}
</i18n>
