<template>
  <div v-if="justification">
    <fw-panel
      :title="$t('absenceJustification')"
      featured
      :loading="savingData"
      after-loading-checked
      :loading-error="savingDataError"
    >
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="editMode && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('hasErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button :type="!editMode ? 'link' : 'link-light'" @click.native="toggleEditMode()">
              {{ editMode ? $t('close') : $t('edit') }}
            </fw-button>
            <fw-button v-if="editMode" type="primary" @click.native="saveJustification()">{{ $t('save') }}</fw-button>
          </div>
        </div>
      </template>
    </fw-panel>

    <fw-panel :title="$t('personalDetails')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
      <div>
        <fw-label>{{ $t('fullName') }}</fw-label>
        <div>{{ user.full_name }}</div>
      </div>

      <div>
        <fw-label>{{ $t('employeeNumber') }}</fw-label>
        <div>{{ user.number || $t('notDefined') }}</div>
      </div>
    </fw-panel>

    <fw-panel v-if="!loading" :title="'Metadados'" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
      <div>
        <fw-label>{{ $t('absenceMotive') }}</fw-label>
        <b-select
          v-if="editMode && canEdit"
          v-model="$v.tmpJustification.motive.$model"
          class="fw-select w-full"
          :disabled="!canEdit || !editMode"
          :placeholder="$t('selectMotive')"
          @input="$v.$reset()"
        >
          <option v-for="(motive, key) in justificationTypes" :key="key" :value="key">{{ motive.label }}</option>
        </b-select>
        <div v-else>
          <div v-if="tmpJustification.motive">
            {{ $t(`motives.${tmpJustification.motive}`) }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.motive || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="tmpJustification && selectedJustificationType.helpText" class="mb-0">
          <span v-html="selectedJustificationType.helpText"></span>
        </fw-tip>

        <fw-tip v-if="$v.tmpJustification.motive.$error" error>
          {{ $t('errors.required.motive') }}
        </fw-tip>
      </div>

      <div v-if="tmpJustification.motive == 'deathOfRelativeOrKin'">
        <fw-label>{{ $t('deathDate') }}</fw-label>
        <b-datepicker
          v-if="editMode && canEdit"
          v-model="$v.tmpJustification.death_date.$model"
          :max-date="maxDate"
          :disabled="!canEdit || !editMode"
          locale="pt-PT"
          icon="calendar-alt"
          trap-focus
          :placeholder="$t('deathDate')"
          class="w-1/4"
        >
        </b-datepicker>
        <div v-else>
          <div v-if="tmpJustification.death_date">
            {{ tmpJustification.death_date | formatDate }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.death_date || $t('notDefined') }}</fw-panel-info>
        </div>
        <fw-tip v-if="$v.tmpJustification.death_date.$error" error>
          {{ $t('errors.invalid.date') }}
        </fw-tip>
      </div>
      <BlockJustificationDates
        :justification="tmpJustification"
        :selected-justification-type="selectedJustificationType"
        :justification-types="justificationTypes"
        :vuelidate-justification="$v.tmpJustification"
        :can-edit="canEdit"
        :edit-mode="editMode"
        @add-date-interval="addDateInterval"
        @delete-date-interval="deleteDateInterval"
      />
      <div class="flex flex-col">
        <div class="flex gap-8 items-center">
          <div v-if="selectedJustificationType.withTime">
            <fw-label>{{ $t('startTime') }}</fw-label>
            <b-timepicker
              v-if="editMode && canEdit"
              v-model="$v.tmpJustification.start_time.$model"
              :placeholder="$t('startTime')"
              :disabled="!canEdit || !editMode"
              editable
              icon-pack="fa"
              icon="clock"
              trap-focus
              locale="pt-PT"
              hour-format="24"
              :max-time="maxDate"
            >
            </b-timepicker>
            <div v-else>
              <div v-if="tmpJustification.start_time">
                {{ tmpJustification.start_time | formatTime }}
              </div>
              <fw-panel-info v-else clean>{{ tmpJustification.start_time || $t('notDefined') }}</fw-panel-info>
            </div>
            <fw-tip v-if="$v.tmpJustification.start_time.$error" error>
              {{ $t('errors.invalid.time') }}
            </fw-tip>
          </div>

          <div v-if="selectedJustificationType.withTime">
            <fw-label>{{ $t('endTime') }}</fw-label>
            <b-timepicker
              v-if="editMode && canEdit"
              v-model="$v.tmpJustification.end_time.$model"
              editable
              :placeholder="$t('endTime')"
              :disabled="!canEdit || !editMode"
              locale="pt-PT"
              icon-pack="fa"
              icon="clock"
              hour-format="24"
              trap-focus
              :max-time="maxDate"
            >
            </b-timepicker>
            <div v-else>
              <div v-if="tmpJustification.end_time">
                {{ tmpJustification.end_time | formatTime }}
              </div>
              <fw-panel-info v-else clean>{{ tmpJustification.end_time || $t('notDefined') }}</fw-panel-info>
            </div>

            <fw-tip v-if="$v.tmpJustification.end_time.$error" error>
              {{ $t('errors.invalid.time') }}
            </fw-tip>
          </div>
        </div>

        <div v-if="tmpJustification.motive == 'deathOfRelativeOrKin'">
          <fw-tip>{{ daysDifference }} {{ $t('days') }}</fw-tip>
          <fw-tip v-if="tmpJustification.relative_type == 'spouseOrAlike' && daysDifference > 5" error>
            {{ $t('errors.maxDays', { days: 5 }) }}
          </fw-tip>
          <fw-tip v-if="tmpJustification.relative_type == '1stDegreeRelative' && daysDifference > 20" error>
            {{ $t('errors.maxDays', { days: 20 }) }}
          </fw-tip>
          <fw-tip v-if="tmpJustification.relative_type == '2ndDegreeRelative' && daysDifference > 2" error>
            {{ $t('errors.maxDays', { days: 2 }) }}
          </fw-tip>
        </div>
      </div>

      <div v-if="tmpJustification.motive == 'nonAttributableFacts'">
        <fw-label class="mt-4">Tipo</fw-label>
        <OptionsCard
          v-if="editMode && canEdit"
          :options="nonAttributableFactsTypes"
          :selected="tmpJustification.unattributable_facts"
          :disabled="!canEdit || !editMode"
          @selected="selectUnattributableFacts"
        ></OptionsCard>
        <div v-else>
          <div v-if="tmpJustification.unattributable_facts">
            {{ $t(`nonAttributableFactsTypes.${tmpJustification.unattributable_facts}`) }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.unattributable_facts || $t('notDefined') }}</fw-panel-info>
        </div>
        <fw-tip v-if="$v.tmpJustification.unattributable_facts.$error" error
          >{{ $t('errors.required.option') }}
        </fw-tip>

        <fw-label class="mt-4">{{ $t('description') }}</fw-label>
        <b-input
          v-if="editMode && canEdit"
          v-model="$v.tmpJustification.unattributable_facts_description.$model"
          type="textarea"
          :disabled="!canEdit || !editMode"
          :rows="4"
          :placeholder="$t('description')"
          :maxlength="65000"
        ></b-input>
        <div v-else>
          <div v-if="tmpJustification.unattributable_facts_description">
            {{ tmpJustification.unattributable_facts_description }}
          </div>
          <fw-panel-info v-else clean>{{
            tmpJustification.unattributable_facts_description || $t('notDefined')
          }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.tmpJustification.unattributable_facts_description.$error" error>
          <span v-if="!$v.tmpJustification.unattributable_facts_description.required">{{
            $t('errors.required.description')
          }}</span>
          <span v-else>{{ $t('errors.invalid.description') }}</span>
        </fw-tip>
      </div>

      <div v-if="tmpJustification.motive == 'deathOfRelativeOrKin'">
        <fw-label>{{ $t('relativeType') }}</fw-label>
        <OptionsCard
          v-if="editMode && canEdit"
          :options="relationshipTypes"
          :selected="tmpJustification.relative_type"
          :disabled="!canEdit || !editMode"
          @selected="selectRelativeOption"
        ></OptionsCard>
        <div v-else>
          <div v-if="tmpJustification.relative_type">
            {{ $t(`relationshipTypes.${tmpJustification.relative_type}`) }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.relative_type || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.tmpJustification.relative_type.$error" error>{{ $t('errors.required.relativeType') }} </fw-tip>
      </div>

      <div v-if="tmpJustification.motive == 'medical'">
        <fw-label>{{ $t('patientType') }}</fw-label>
        <OptionsCard
          v-if="editMode && canEdit"
          :options="patientTypes"
          :selected="tmpJustification.patient_type"
          :disabled="!canEdit || !editMode"
          @selected="selectMedicalOption"
        ></OptionsCard>
        <div v-else>
          <div v-if="tmpJustification.patient_type">
            {{ $t(`patientTypes.${tmpJustification.patient_type}`) }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.patient_type || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="tmpJustification.patient_type && tmpJustification.patient_type != 'itself'">{{
          $t('proofOfMostSuitablePerson')
        }}</fw-tip>

        <fw-tip v-if="$v.tmpJustification.patient_type.$error" error>{{ $t('errors.required.patientType') }} </fw-tip>
      </div>

      <div v-if="tmpJustification.motive == 'timeCreditUse'">
        <fw-label class="mt-4">{{ $t('hoursAvailable') }}</fw-label>
        <NumberInput
          v-if="editMode && canEdit"
          v-model="$v.tmpJustification.available_hours.$model"
          :placeholder="$t('hoursAvailable')"
          :disabled="!canEdit || !editMode"
        ></NumberInput>
        <div v-else>
          <div v-if="tmpJustification.available_hours">
            {{ tmpJustification.available_hours }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.available_hours || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.tmpJustification.available_hours.$error" error>
          <span v-if="!$v.tmpJustification.available_hours.required">{{ $t('errors.required.hoursAvailable') }}</span>
          <span v-else>{{ $t('errors.invalid.hours') }}</span>
        </fw-tip>

        <fw-label class="mt-4">{{ $t('hoursToAuthorize') }}</fw-label>
        <NumberInput
          v-if="editMode && canEdit"
          v-model="$v.tmpJustification.hours_to_use.$model"
          :placeholder="$t('hoursToAuthorize')"
          :disabled="!canEdit || !editMode"
        ></NumberInput>
        <div v-else>
          <div v-if="tmpJustification.hours_to_use">
            {{ tmpJustification.hours_to_use }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.hours_to_use || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.tmpJustification.hours_to_use.$error" error>
          <span v-if="!$v.tmpJustification.hours_to_use.required">{{ $t('errors.required.hoursToAuthorize') }}</span>
          <span v-else>{{ $t('errors.invalid.hours') }}</span>
        </fw-tip>
      </div>

      <div v-if="!loading && tmpJustification && selectedJustificationType.justificationFile">
        <div class="flex items-center">
          <fw-label>{{ $t('motiveJustification') }}</fw-label>
          <div
            v-if="tmpJustification.motive != 'other'"
            class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold"
          >
            {{ $t('optional') }}
          </div>
        </div>

        <b-input
          v-if="editMode && canEdit"
          v-model="$v.tmpJustification.description.$model"
          type="textarea"
          :disabled="!canEdit || !editMode"
          :rows="4"
          :placeholder="'Descreva o motivo/justificação legal'"
          :maxlength="65000"
        ></b-input>
        <div v-else>
          <div v-if="tmpJustification.description">
            {{ tmpJustification.description }}
          </div>
          <fw-panel-info v-else clean>{{ tmpJustification.description || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.tmpJustification.description.$error" error>
          <span v-if="!$v.tmpJustification.description.required">{{ $t('errors.required.description') }}</span>
          <span v-else>{{ $t('errors.invalid.description') }}</span>
        </fw-tip>

        <fw-label>Anexos</fw-label>
        <div v-if="tmpJustification.files && tmpJustification.files.length > 0" class="files mb-1.5">
          <RecordFileEntry
            v-for="(file, f) in tmpJustification.files"
            :key="file.key"
            :can-edit="false"
            :can-remove="canEdit && editMode"
            :allow-classified="false"
            :file="file"
            @remove="removeFile(f, $event)"
            @download="downloadFile(file)"
          />
        </div>
        <fw-panel-info v-else-if="!editMode" clean>{{ $t('notDefined') }}</fw-panel-info>
        <Uploader
          v-if="canEdit && editMode"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_justification"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_justification"
          :files.sync="files"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="justification"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded"
        />
        <fw-tip v-if="$v.tmpJustification.files.$error" error>
          {{ $t('errors.required.file') }}
        </fw-tip>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import OptionsCard from '@/fw-modules/fw-core-vue/ui/components/form/OptionsCard'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import { required, requiredIf, maxValue, minValue, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import BlockJustificationDates from '@/components/blocks/BlockJustificationDates.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    Uploader,
    RecordFileEntry,
    OptionsCard,
    NumberInput,
    BlockJustificationDates
  },

  props: {
    savingData: {
      type: Boolean,
      default: false
    },
    savingDataError: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    absence: {
      type: Object,
      default: () => {}
    },
    justification: {
      type: Object,
      required: true,
      default: () => {}
    },
    user: {
      type: Object,
      required: true,
      default: () => {}
    },
    justificationTypes: {
      type: Object,
      required: true,
      default: () => {}
    },
    patientTypes: {
      type: Array,
      required: true,
      default: () => []
    },
    nonAttributableFactsTypes: {
      type: Array,
      required: true,
      default: () => []
    },
    relationshipTypes: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data() {
    return {
      editMode: this.canEdit,
      files: [],
      tmpJustification: {},
      maxDate: new Date()
    }
  },

  validations() {
    return {
      tmpJustification: {
        motive: { required },
        death_date: { max: maxValue(this.maxDate) },
        dates: {
          required,
          min: minLength(1),
          $each: {
            start_at: { required, max: maxValue(this.maxDate) },
            end_at: {
              required,
              max: maxValue(this.maxDate)
            }
          }
        },
        start_time: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && this.justificationTypes[nestedModel.motive].withTime
          }),
          max: maxValue(this.maxDate)
        },
        end_time: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && this.justificationTypes[nestedModel.motive].withTime
          }),
          min: minValue(this.tmpJustification.start_time)
        },
        patient_type: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'medical'
          })
        },
        relative_type: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'deathOfRelativeOrKin'
          })
        },
        unattributable_facts: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'nonAttributableFacts'
          })
        },
        unattributable_facts_description: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'nonAttributableFacts'
          }),
          max: maxLength(65000)
        },
        available_hours: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'timeCreditUse'
          }),
          min: minValue(0),
          numeric
        },
        hours_to_use: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'timeCreditUse'
          }),
          min: minValue(0),
          numeric
        },
        description: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && nestedModel.motive == 'others'
          }),
          max: maxLength(65000)
        },
        files: {
          required: requiredIf(nestedModel => {
            return nestedModel.motive && this.justificationTypes[nestedModel.motive].justificationFile
          }),
          min: minLength(1)
        }
      }
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },

    selectedJustificationType() {
      return this.justificationTypes && this.tmpJustification.motive
        ? this.justificationTypes[this.tmpJustification.motive]
        : {}
    },

    daysDifference() {
      if (!this.tmpJustification.dates.length) return 0
      return Dates.build(this.tmpJustification.dates[0].end_at).diff(
        Dates.build(this.tmpJustification.dates[0].start_at),
        'day'
      )
    }
  },

  watch: {
    selectedJustificationType(newVal) {
      if (!newVal.multipleDates) {
        this.$set(this.tmpJustification, 'dates', [this.tmpJustification.dates[0]])
      }
    }
  },

  mounted() {
    this.tmpJustification = Object.assign({}, this.justification)
  },

  methods: {
    uploaded(files) {
      const newfiles = []
      console.log('uploaded files', files)
      for (const file of files) {
        if (file.response.data && file.response.status === 'success') {
          newfiles.push(file.response.data.file)
        }
      }

      this.tmpJustification.files = this.tmpJustification.files.concat(newfiles)
    },

    removeFile(file) {
      console.log('remove', file)
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          this.tmpJustification.files.splice(file, 1)
        }
      })
    },

    downloadFile(file) {
      console.log('download', file)
      const url = ServiceStorage.getFileUrl(file, this.loggedUser.token)
      utils.downloadFile(url, file.filename)
    },

    selectUnattributableFacts(option) {
      this.$set(this.tmpJustification, 'unattributable_facts', option)
      this.$v.tmpJustification.unattributable_facts.$touch()
    },

    selectRelativeOption(option) {
      this.$set(this.tmpJustification, 'relative_type', option)
      this.$v.tmpJustification.relative_type.$touch()
    },

    selectMedicalOption(option) {
      this.$set(this.tmpJustification, 'patient_type', option)
      this.$v.tmpJustification.patient_type.$touch()
    },

    addDateInterval() {
      this.tmpJustification.dates.push({
        start_at: new Date(),
        end_at: new Date()
      })
    },

    deleteDateInterval(index) {
      console.log('deleteDateInterval :>> ', index)
      this.tmpJustification.dates.splice(index, 1)
    },

    saveJustification() {
      this.$v.$touch()
      console.log('invalid :>> ', this.$v.$invalid)
      if (this.$v.$invalid) return

      if (!this.selectedJustificationType.multipleDates && this.tmpJustification.dates.length > 1) {
        this.tmpJustification.dates = [this.tmpJustification.dates[0]]
      }
      if (!this.selectedJustificationType.withTime) {
        this.tmpJustification.start_time = null
        this.tmpJustification.end_time = null
      }
      if (!this.selectedJustificationType.justificationFile) {
        this.tmpJustification.files = []
      }
      console.log('this.tmpJustification :>> ', this.tmpJustification)
      this.$emit('save', this.tmpJustification)
      this.$v.$reset()
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
    "edit": "Editar",
    "notDefined": "Não definido",
    "personalDetails": "Dados pessoais",
    "fullName": "Nome completo",
    "employeeNumber": "Número de funcionário",
    "absenceJustification": "Justificação de ausência",
    "startDate": "Data de início",
    "endDate": "Data de fim",
    "startTime": "Hora de início",
    "endTime": "Hora de fim",
    "absenceMotive": "Motivo da falta",
    "uploadFiles": "Carregar ficheiros",
    "hasErrors": "Existem erros no formulário",
    "cancel": "Cancelar",
    "reopen": "Reabrir",
    "submit": "Submeter",
    "save": "Guardar",
    "hoursAvailable": "Nº de horas disponível",
    "hoursToAuthorize": "Nº de horas a autorizar",
    "relativeType": "Tipo de relação",
    "patientType": "Paciente",
    "days": "Dias",
    "deleteFileConfirm": "Tem certeza que deseja apagar este ficheiro?",
    "proofOfMostSuitablePerson": "Nestas situações é necessário comprovar que o/a trabalhador/a é a pessoa mais adequada para o fazer",
    "motiveJustification": "Motivo/justificação",
    "deathDate": "Data do óbito, do seu conhecimento ou da cerimónia fúnebre",
    "selectMotive": "Selecione o motivo da falta",
    "description": "Descrição",
    "motives": {
      "wedding": "Casamento",
      "deathOfRelativeOrKin": "Falecimento de familiar ou afins",
      "nonAttributableFacts": "Factos não imputáveis ao/à trabalhador/a",
      "medical": "Pela necessidade de tratamento ambulatório, realização de consultas médicas ou exames complementares de diagnóstico",
      "educationalSituationOfMinor": "Deslocação à escola para se inteirar da situação educativa de filho/a menor",
      "firstDayOfSchool": "Acompanhamento de filho/a menor de 12 anos no primeiro dia de escola",
      "bloodDonationFirstAid": "Doação de sangue e socorrismo",
      "procedure": "Necessidade de submissão a métodos de seleção em procedimento concursal",
      "electedWorkers": "Trabalhadores/as eleitos/as para estruturas de representação coletiva",
      "publicOfficeCandidate": "Candidatos/as a eleições para cargos públicos durante o período legal da campanha eleitoral",
      "prenatalConsultations": "Dispensa para consultas pré-natais",
      "timeCreditUse": "Gozo de crédito de horas do mês anterior",
      "externalService": "Serviço externo no(s) dia(s)",
      "occasionalInterruptions": "Interrupções ocasionais",
      "annualCredit": "Crédito Anual",
      "other": "Outras faltas previstas na Lei"
    },
    "relationshipTypes": {
      "spouseOrAlike": "Cônjuge ou equiparado, parente/afim ascendente no 1.º grau da linha reta",
      "1stDegreeRelative": "Descendente/afim no 1.º grau da linha reta",
      "2ndDegreeRelative": "Outro parente/afim no 2.º grau da linha reta ou no 2º grau da linha colateral"
    },
    "nonAttributableFactsTypes": {
      "legalObligations": "Cumprimento obrigações legais",
      "others": "Outros"
    },
    "patientTypes": {
      "itself": "Do/a próprio/a",
      "minorsOrDisabled": "Menores ou deficientes",
      "family": "Do/a cônjuge ou equiparado, ascendentes, descendentes, adotandos/as, adotados/as e enteados/as"
    },
    "status": {
      "draft": "Rascunho"
    },
    "errors": {
      "required": {
        "motive": "Insira o motivo da falta",
        "option": "Escolhar uma opção",
        "description": "Insira uma descrição",
        "relativeType": "Insira o tipo de relação",
        "patientType": "Insira a relação com o paciente",
        "hoursAvailable": "Insira o nº de horas a disponível",
        "hoursToAuthorize": "Insira o nº de horas a autorizar",
        "file": "Insira pelo menos um ficheiro"
      },
      "invalid": {
        "date": "Data inválida",
        "time": "Hora inválida",
        "description": "Descrição inválida",
        "hours": "Nº de horas inválido"
      },
      "maxDays": "até {days} dias"
    }
  },
  "en": {
    "close": "Close",
    "edit": "Edit",
    "notDefined": "Not defined",
    "personalDetails": "Personal details",
    "fullName": "Full name",
    "employeeNumber": "Employee number",
    "absenceJustification": "Absence justification",
    "startDate": "Start date",
    "endDate": "End date",
    "startTime": "Start time",
    "endTime": "End time",
    "absenceMotive": "Reason for absence",
    "uploadFiles": "Upload files",
    "hasErrors": "There are errors in the form",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "submit": "Submit",
    "save": "Save",
    "hoursAvailable": "No. of hours available",
    "hoursToAuthorize": "No. of hours to be authorized",
    "relativeType": "Relationship type",
    "patientType": "Patient type",
    "deleteFileConfirm": "Are you sure you want to delete this file?",
    "proofOfMostSuitablePerson": "In these situations it is necessary to prove that you are the most suitable person to do it",
    "motiveJustification": "Reason/justification",
    "deathDate": "Date of death, its knowledge or the funeral ceremony",
    "days": "Days",
    "selectMotive": "Select the reason for the absence",
    "description": "Descrição",
    "motives": {
      "wedding": "Wedding",
      "deathOfRelativeOrKin": "Death of relative or kin",
      "nonAttributableFacts": "Factors not attributable to the employee",
      "medical": "For the need for outpatient treatment, medical appointments, or complementary diagnostic tests",
      "educationalSituationOfMinor": "Trip to the school to find out about the educational situation of a minor child",
      "firstDayOfSchool": "Accompaniment of a child under 12 on the first day of school",
      "bloodDonationFirstAid": "Blood donation and first aid",
      "procedure": "Need to submit to selection methods in a competitive bidding procedure",
      "electedWorkers": "Workers elected to collective representation structures",
      "publicOfficeCandidate": "Candidates for public office during the legal period of the election campaign",
      "prenatalConsultations": "Exemption for prenatal consultations",
      "timeCreditUse": "Use of time credit from the previous month",
      "externalService": "External service on the day(s)",
      "occasionalInterruptions": "Occasional interruptions",
      "annualCredit": "Annual Credit",
      "other": "Other absences foreseen in the law"
    },
    "relationshipTypes": {
      "spouseOrAlike": "Spouse or alike, relative/kin in 1st degree ascending line",
      "1stDegreeRelative": "Descendant/Affiliate in the 1st degree in a straight line",
      "2ndDegreeRelative": "Other relative/kin in the 2nd degree in the direct line or in the 2nd degree in the collateral line"
    },
    "nonAttributableFactsTypes": {
      "legalObligations": "Compliance with legal obligations",
      "others": "others"
    },
    "patientTypes": {
      "itself": "From yourself",
      "minorsOrDisabled": "Minors or disabled",
      "family": "Of the spouse or alike, ascendants, descendants, adoptees, foster parents and stepchildren"
    },
    "status": {
      "draft": "Draft"
    },
    "errors": {
      "required": {
        "motive": "Enter reason for absence",
        "option": "Choose an option",
        "relativeType": "Enter the relationship type",
        "patientType": "Enter the relationship with the patient",
        "description": "Enter a description",
        "hoursAvailable": "Enter the number of hours to be available",
        "hoursToAuthorize": "Enter the number of hours to be authorized",
        "file": "Insert at least one file"
      },
      "invalid": {
        "date": "Invalid date",
        "time": "Invalid time",
        "hours": "Invalid no. of hours",
        "description": "Invalid description"
      },
      "maxDays": "until {days} days"
    }
  }
}
</i18n>
